import { Typography } from '@mui/material';
import * as React from "react";
import styled from "styled-components";

const Subheading = styled(Typography)`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(Typography)`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled(Typography)`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  text-transform: uppercase;
  font-family: var(--text-regular-light);
  color: var(--text-alternate);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  // width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const SubHeading3 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading3 = styled(Typography)`
  &.MuiTypography-h3 {
    font-size: var(--heading-desktop-h4-size);
    align-self: stretch;
    position: relative;
    line-height: 130%;
    text-transform: uppercase;
    margin: 0
  }
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--heading-desktop-h4-size);
  color: var(--primary-highlight);
  font-family: var(--heading-desktop-h4);
`;
const ContentTop = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Button1 = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const Actions = styled.div`
  width: 22rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-medium-light-size);
`;
const Content2 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-13xl);
  gap: var(--gap-5xl);
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Image1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--text-alternate);
  border: 1px solid var(--primary-highlight);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 30%;
`;
const Text3 = styled.div`
  width: 27.938rem;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
  display: none;
`;
const Actions1 = styled.div`
  width: 27.938rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--text-medium-light-size);
`;
const PlaceholderImageIcon1 = styled.img`
  width: 27rem;
  position: relative;
  height: 23.75rem;
  object-fit: cover;
`;
const Image2 = styled.div`
  width: 22.063rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Card1 = styled.div`
  align-self: stretch;
  background-color: var(--text-alternate);
  border: 1px solid var(--primary-highlight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ThisMapIs = styled.p`
  margin-block-start: 0;
  margin-block-end: 8px;
`;
const GiantPorphyryRelated = styled.p`
  margin: 0;
`;
const PlaceholderImageIcon2 = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Image3 = styled.div`
  width: 22.063rem;
  height: 23.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  // width: 54rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Row1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
   @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const PlaceholderImage = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
`;
const Image4 = styled.div`
  width: 22.063rem;
  height: 23.75rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Card2 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--text-alternate);
  border: 1px solid var(--primary-highlight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Row2 = styled.div`
  align-self: stretch;
  // width: 54rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Content3 = styled.div`
  width: 26rem;
  height: 0.063rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
`;
const Image5 = styled.div`
  align-self: stretch;
  // height: 34.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Card3 = styled.div`
  // flex: 1;
  background-color: var(--text-alternate);
  border: 1px solid var(--primary-highlight);
  box-sizing: border-box;
  // height: 35rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  color: var(--border-primary);
`;
const LayoutRoot = styled.section`
  // width: 90rem;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: center;
  font-size: var(--text-regular-light-size);
  color: var(--text-alternate);
  font-family: var(--text-regular-light);
`;

const LocationSection = () => {
  return (
    <LayoutRoot id="first-section">
      <SectionTitle>
        {/* <Subheading>Tagline</Subheading> */}
        <Content>
          <Heading variant='h2'>PROJECT LOCATION</Heading>
          {/* <Text1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Text1> */}
        </Content>
      </SectionTitle>
      <Container>
        <Row1>
          <Card>
            <Content2>
              <ContentTop>
                <SubHeading3>Project Boundary</SubHeading3>
                <Content1>
                  <Heading3 variant='h3'>
                    Map showing PFS drillholes and MPSA boundary
                  </Heading3>
                  <Text2>
                    The Mankayan Project is under a Mineral Production Sharing
                    Agreement, MPSA 057-96-CAR of Crescent Mining and
                    Development Corporation (“Crescent”) covering 533.4 hectares
                    located in barangays Guinaoang and Bulalacao, Mankayan,
                    Benguet.
                  </Text2>
                </Content1>
              </ContentTop>
              <Actions>
                <Button2>
                  <Button1>Button</Button1>
                  <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                </Button2>
              </Actions>
            </Content2>
            <Image1>
              <PlaceholderImageIcon alt="" src="/placeholder-image5@2x.png" />
            </Image1>
          </Card>
          <Row>
            <Card1>
              <Content2>
                <ContentTop>
                  <SubHeading3>Project Location</SubHeading3>
                  <Content1>
                    <Heading3 variant='h3'>
                      Located 340km from Manila in Northern Luzon
                    </Heading3>
                    <Text3>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Text3>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image2>
                <PlaceholderImageIcon1
                  alt=""
                  src="/placeholder-image6@2x.png"
                />
              </Image2>
            </Card1>
            <Card1>
              <Content2>
                <ContentTop>
                  <SubHeading3>Regional Geology</SubHeading3>
                  <Content1>
                    <Heading3 variant='h3'>Major Intrusive Complexes</Heading3>
                    <Text2>
                      <ThisMapIs>{`This map is showing the distribution of porphyry Cu-Au deposits relative to the major intrusive complexes. `}</ThisMapIs>
                      <GiantPorphyryRelated>
                        Giant porphyry related metal camps typical of the
                        Pacific Rim of Fire.
                      </GiantPorphyryRelated>
                    </Text2>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image3>
                <PlaceholderImageIcon2
                  alt=""
                  src="/placeholder-image7@2x.png"
                />
              </Image3>
            </Card1>
          </Row>
        </Row1>
        <Row1>
          <Row2>
            <Card2>
              <Content2>
                <ContentTop>
                  <SubHeading3>Planning</SubHeading3>
                  <Content1>
                    <Heading3 variant='h3'>Drill hole location plan</Heading3>
                    <Text2>
                      <ThisMapIs>{`This map is showing the distribution of porphyry Cu-Au deposits relative to the major intrusive complexes. `}</ThisMapIs>
                      <GiantPorphyryRelated>
                        Giant porphyry related metal camps typical of the
                        Pacific Rim of Fire.
                      </GiantPorphyryRelated>
                    </Text2>
                  </Content1>
                </ContentTop>
                <Actions1>
                  <Button2>
                    <Button1>Button</Button1>
                    <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                  </Button2>
                </Actions1>
              </Content2>
              <Image4>
                <PlaceholderImage />
              </Image4>
            </Card2>
          </Row2>
          <Card3>
            <Content3>
              <ContentTop>
                <SubHeading3>Project Boundary</SubHeading3>
                <Content1>
                  <Heading3 variant='h3'>
                    Map showing PFS drillholes and MPSA boundary
                  </Heading3>
                  <Text2>
                    The Mankayan Project is under a Mineral Production Sharing
                    Agreement, MPSA 057-96-CAR of Crescent Mining and
                    Development Corporation (“Crescent”) covering 533.4 hectares
                    located in barangays Guinaoang and Bulalacao, Mankayan,
                    Benguet.
                  </Text2>
                </Content1>
              </ContentTop>
              <Actions>
                <Button2>
                  <Button1>Button</Button1>
                  <IconChevronRight alt="" src="/icon--chevronright2.svg" />
                </Button2>
              </Actions>
            </Content3>
            <Image5>
              <PlaceholderImageIcon2 alt="" src="/placeholder-image@2x.png" />
            </Image5>
          </Card3>
        </Row1>
      </Container>
    </LayoutRoot>
  );
};

export default LocationSection;
