import * as React from "react";
import { useState, useCallback } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import CustomButton from "../components/CustomButton";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import LocationSection from "../components/location-section";
import TablesSection from "../components/tables-section";
import DepositSection from "../components/deposit-section";
import DotchartSection from "../components/dotchart-section";
import HistorySection from "../components/history-section";
import StatsTwo from "../components/stats-two";
import CommunitySection from "../components/community-section";
import ContainerheaderMainNav from '../components/containerheader-main-nav';
import Footer1 from '../components/footer1';
import DiscoverCTA from '../components/discover-c-t-a';

const IdmLogo22Icon = styled.img`
  width: 5.831rem;
  position: relative;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const ImageLogo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Buttonmuitext = styled(Button)`
  align-self: stretch;
  cursor: pointer;
`;
const AboutUs = styled(Button)``;
const Some = styled(MenuItem)``;
const Menu1 = styled(Menu)``;
const NavLinkDropdown = styled.div`
  align-self: stretch;
  cursor: pointer;
  &:hover (I12703:29426;12703:1097) {
    display: flex;
  }
`;
const Textp = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const NavLink = styled.div`
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-xs);
`;
const Textp1 = styled.p`
  margin: 0;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  z-index: 0;
`;
const VectorIcon = styled.img`
  width: 0.788rem;
  position: relative;
  height: 0.444rem;
`;
const ChevronDown = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-7xs);
  z-index: 1;
`;
const IconSmile = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const PageOne = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-size);
  line-height: 150%;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const MenuItem1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  gap: var(--gap-xs);
`;
const MenuItem2 = styled.div`
  width: 21.5rem;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  box-sizing: border-box;
  gap: var(--gap-xs);
`;
const animationFadeInTop = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
const SubmenuAbout = styled.div`
  width: 23rem;
  margin: 0 !important;
  position: absolute;
  top: 4.375rem;
  left: -0.375rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-7xs);
  z-index: 2;
  font-size: var(--text-regular-normal-size);
  &:hover {
    display: flex;
    animation: 1s ease 0s 1 normal none ${animationFadeInTop};
    opacity: 1;
  }
`;
const NavLinkDropdown1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0rem var(--padding-xs);
  position: relative;
  gap: var(--gap-9xs);
`;
const ContainernavMenu = styled.nav`
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--text-regular-caps-size);
  color: var(--text-alternate);
  font-family: var(--text-tiny-normal);
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const HamburgerMenuIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  @media screen and (max-width: 960px) {
    display: flex;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;
const AccountSection = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ButtonmuicontainedText = styled.div`
  background-color: var(--primary-main);
  border: 1px solid var(--border-primary);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const ButtonmuitextIcon = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  gap: var(--gap-5xs);
  color: var(--primary-main);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 0rem 0rem var(--padding-21xl);
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    padding-left: 0rem;
    box-sizing: border-box;
  }
`;
const ContainersectionMenuwrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
`;
const Navwrapper = styled.header`
  align-self: stretch;
  height: 4.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem var(--padding-45xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--text-alternate);
  font-family: var(--text-tiny-normal);
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const ContainerheaderMainnav = styled.header`
  align-self: stretch;
  background-color: rgba(34, 29, 23, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Tagline = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading1 = styled(Typography)`
&.MuiTypography-h1 {
  align-self: stretch;
  position: relative;
  text-transform: uppercase;
  // font-weight: 600;
  // font-family: var(--heading-desktop-h4);
  text-align: left;
  color: #221D17;
  }
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const TheMankayanProject1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const Actions1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-base);
  color: var(--color-tan-100);
`;
const Column1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--text-medium-light-size);
`;
const Content1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  max-width: 68.75rem;
`;
const Header = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("/header--47@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--text-regular-normal-size);
  color: var(--color-neutral-neutral-darker);
  font-family: var(--text-tiny-normal);
`;
const IdmLogo22Icon1 = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const ImageLogo1 = styled.div`
  width: 10rem;
  height: 4.125rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Address = styled.span`
  font-weight: 600;
`;
const OrdStreetWest = styled.span``;
const Address33OrdContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Phone = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Content3 = styled.div`
  width: 54rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const IconFacebook = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Content4 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const Column2 = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const Contact = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const Faqs = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
`;
const Blog = styled.div`
  width: 11.25rem;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  display: none;
`;
const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Column3 = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-21xl);
  gap: var(--gap-51xl);
  font-size: var(--text-medium-light-size);
  @media screen and (max-width: 960px) {
    flex-direction: row;
    padding-left: 0rem;
    padding-top: var(--padding-21xl);
    padding-right: 0rem;
    box-sizing: border-box;
  }
`;
const Content5 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const Divider = styled.div`
  align-self: stretch;
  position: relative;
  background-color: var(--border-primary);
  border: 1px solid var(--text-alternate);
  box-sizing: border-box;
  height: 0.063rem;
`;
const IdmAllRights = styled.div`
  position: relative;
  line-height: 150%;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const PrivacyPolicy = styled.div`
  position: relative;
  text-decoration: underline;
  line-height: 150%;
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Credits = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Footer = styled.footer`
  width: 90rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-medium-size);
  color: var(--text-alternate);
  font-family: var(--text-tiny-normal);
`;
const TheMankayanProjectRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TheMankayanProject = () => {
  const [navLinkDropdown1AnchorEl, setNavLinkDropdown1AnchorEl] =
    useState(null);
  const navLinkDropdown1Open = Boolean(navLinkDropdown1AnchorEl);
  const handleNavLinkDropdown1Click = (event) => {
    setNavLinkDropdown1AnchorEl(event.currentTarget);
  };
  const handleNavLinkDropdown1Close = () => {
    setNavLinkDropdown1AnchorEl(null);
  };

  const onButtonmuitext01Click = useCallback(() => {
    navigate("/");
  }, []);

  const onNavLinkDropdown1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButtonmuitext02Click = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  const onFAQsTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onHomeTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onAboutTextClick = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onProjectTextClick = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  const onInvestorsTextClick = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  return (
    <TheMankayanProjectRoot>
      <Header>
        <ContainerheaderMainNav />
        <Content1>
          <Column>
            <Tagline>Potential</Tagline>
            <Heading1 variant='h1'>
              Unprecedented Copper-Gold Project
            </Heading1>
          </Column>
          <Column1>
            <TheMankayanProject1>
              The Mankayan Project stands as a monumental copper-gold porphyry
              endeavor, boasting unparalleled scale and global significance.
              With its 25-year mining license and strategic location in Northern
              Luzon, this project holds immense potential for investors and
              stakeholders alike.
            </TheMankayanProject1>
            <Actions1>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  document.getElementById('first-section').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Learn More
              </CustomButton>
              <CustomButton
                variant="outlined"
                color="primary"
                isChatButton={true}
                sx={{ backgroundColor: "#221d17a6" }}
              >
                Sign Up
              </CustomButton>
            </Actions1>
          </Column1>
        </Content1>
      </Header>
      <LocationSection id="first-section" />
      <TablesSection />
      <DepositSection />
      <DotchartSection />
      <HistorySection />
      <StatsTwo />
      <CommunitySection />
      <DiscoverCTA />
      <Footer1 />
    </TheMankayanProjectRoot>
  );
};

export default TheMankayanProject;
